import React, { createContext, useState, useEffect } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../utilities/callApi';
import useUserRole from './use-UserRole'
export const ConfigConstantsContext = createContext();

const ConfigConstantsContextProvider = (props) => {
  const [configDetails, setconfigDetails] = useState({});
  const [loading , setLaoding] = useState(true)
  const userCheck = useUserRole();
  const configURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/get-active-configs`;

  useEffect(() => {
    if(sessionStorage.getItem('tok')){
      callApi.getApiDataLoggedUser(configURL).then((response) => {
        
        let configs = {};
        
        response?.data?.forEach((config) => {
          if(config?.userrole == sessionStorage.getItem("userrole")){
            configs[config.configKey] = config.configValue
          }else if(config?.userrole == null || undefined || "*"){
            if(!(config.configKey in configs)){
              configs[config.configKey] = config.configValue
            }  
          } 
        })
        if(configs != {}){
          sessionStorage.setItem("configs",JSON.stringify(configs))
          setconfigDetails(configs);
        }
        if(response?.name=="AxiosError"){
          setconfigDetails({})
        }
      })
    }
  }, []);

  return (
    <ConfigConstantsContext.Provider
      value={{
        configDetails,
        loading
      }}
    >
      {props.children}
    </ConfigConstantsContext.Provider>
  );
};

export default ConfigConstantsContextProvider;
