import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import Demo from './pages/demo';
import './styles/index.scss';

import AppRouting from './routes/AppRouting';
import _error from './pages/_error';
import GlobalFooter from '../src/components/organisms/GlobalFooter';
import GlobalHeader from '../src/components/organisms/GlobalHeader';
import GlobalHead from '../src/components/organisms/GlobalHead';
import Login from '../src/pages/index.jsx';
import EmployeeRedirect from '../src/pages/EmployeeRedirect';
import FooterTOU from '../src/pages/FooterTOU';
import LoginAssistant from '../src/pages/LoginAssistant';
import DocumentViewerPage from '../src/pages/documentViewer';
import LandingPage from '../src/pages/landing-page/landingPage';
import RedirectLandingPage from '../src/pages/landing-page/redirectLandingPage';
import FindByRequirementId from '../src/pages/landing-page/FindByRequirementId';
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { USBGrid, USBColumn } from '@usb-shield/components-react';

import DocContextProvider from './pages/context/DocContext';
import DashboardContextProvider from './pages/context/DashboardContext';
import PermissionRoleContextProvider from './components/Hooks/usePermissionRoles';
import FeatureFlagsContextProvider from './components/Hooks/useFeatureFlags';
import ConfigConstantsContextProvider from './components/Hooks/useConfigConstants';
import CBDownloadsContextProvider from './pages/context/CBDownloadsContext';
import IBDownloadsContextProvider from './components/Hooks/useDownloadStatusInbox';
import Layout from '../src/components/Layout/Layout';
import DocumentTemplate from '../src/pages/DocumentTemplate';
import Employee from './pages/Employee';
import Termsofuse from '../src/pages/Termsofuse';
import ManageUser from '../src/pages/ManageUser';
import GlobalSearch from './pages/Reports/LookupSearch';
import Notifications from '../src/pages/Notifications';
import ReportingDashboard from '../src/pages/ReportingDashboard';
import ClosingBinderPortfolio from '../src/pages/ClosingBinderPortfolio';
import CBPortfolioParent from '../src/pages/ClosingBinderPortfolio/CBPortfolioParent';
import Inbox from '../src/pages/Inbox/Inbox';
import Requirements from '../src/pages/Requirements/Requirements'
import InboxDetails from '../src/pages/Inbox/InboxDetails';
import InboxParent from '../src/pages/InboxPortfolio/InboxParent';
import InboxRequirement from './pages/RequirementDetails/InboxRequirement';
import ClosingBinders from '../src/pages/RequirementDetails/ClosingBinders';
import CBDoctypeReport from '../src/pages/RequirementDetails/CBDoctypeReport';
import FeatureFlags from '../src/pages/RequirementDetails/FeatureFlags';
import CBFileNettypeReport from '../src/pages/RequirementDetails/CBFileNettypeReport';
import RequirementDetails from '../src/pages/RequirementDetails';
import Reports from '../src/pages/Reports';
import PSAReports from '../src/pages/Reports/PSA report';
import VendorCompanyFilter from './pages/Reports/VendorAccessReport';
import VendorUserFilter from './pages/Reports/VendorUserReport';
import CompanyAccessReport from './pages/Reports/CustomerAccessReport';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@usb-shield/components-react/dist/styles/shield.css';
import './pages/index.css';
import './pages/external.scss';

import '@usb-shield/themes/dist/styles/shield.css';
import '@usb-shield/react-calendar/dist/library/styles/index.css';
import '@usb-shield/react-date-picker/dist/library/styles/index.css';
import '@usb-inner-src/react-table/dist/library/styles/index.css';

import { useHistory } from 'react-router-dom';
import logo from './assets/images/usbank.png';
import { setUserRole } from '../src/components/utilities/Utils';
import RedirectManageUser from './pages/manage-user/RedirectManageUser';

const App = () => {
  const basePath = process.env.BASE_PATH;
  const history = useHistory();
  const updateRedirectUrl = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/LoginAssistant' ||
      window.location.pathname === '/Employee' ||
      window.location.pathname === '/EmployeeRedirect' ||
      window.location.pathname === '/Termsofuse' ||
      window.location.pathname === '/FooterTOU' ||
      window.location.pathname === '/Error'
    ) {
    } else {
      if (!sessionStorage.getItem('tok')) {
        localStorage.setItem('redirectURL', window.location.href);
        const currentUrl =
          typeof window !== 'undefined' ? window.location.href : '';
        if (currentUrl.includes('dns.com')) {
          window.location.href = '/Employee';
        } else {
          window.location.href = '/';
        }
      } else {
        if (localStorage.getItem('redirectURL')) {
          let url = localStorage.getItem('redirectURL')
          if(!url.includes("route=manage")){
            window.location.href = localStorage.getItem('redirectURL');
            localStorage.removeItem('redirectURL');
          }
        }
      }
    }
  };

  useEffect(() => {
    updateRedirectUrl();
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem('userrole')) {
      setUserRole();
    }
  }, []);

  useEffect(() => {
    window.process = {
      ...window.process,
      env: {
        LOG_LEVEL: 'info',
      },
    };
  }, []);

  if ((window.location.pathname).toLocaleLowerCase() === '/welcome') {
    return (
      <DocContextProvider>
        <DashboardContextProvider>
          <ConfigConstantsContextProvider>
            <PermissionRoleContextProvider>
              <FeatureFlagsContextProvider>
                <LandingPage />
              </FeatureFlagsContextProvider>
            </PermissionRoleContextProvider>
          </ConfigConstantsContextProvider>
        </DashboardContextProvider>
      </DocContextProvider>
    );
  }

  if (
    window.location.pathname === '/' ||
    window.location.pathname === '/LoginAssistant' ||
    window.location.pathname === '/Employee' ||
    window.location.pathname === '/EmployeeRedirect' ||
    window.location.pathname === '/Termsofuse' ||
    window.location.pathname === '/FooterTOU' ||
    window.location.pathname === '/Error'
  ) {
    return (
      <DocContextProvider>
        <DashboardContextProvider>
          <GlobalHead title="Login" />
          <USBGrid addClasses="blue-frame top-menu">
            <USBColumn largeSpan={2} />
            <USBColumn largeSpan={12}>
              <ul>
                <li>
                  <div className="usb-logo">
                    <img src={logo}></img>
                  </div>
                </li>
              </ul>
            </USBColumn>
            <USBColumn largeSpan={2} />
          </USBGrid>

          <Router>
            <Route exact path="/">
              {' '}
              <Login />{' '}
            </Route>
            <Route path="/LoginAssistant">
              {' '}
              <LoginAssistant />{' '}
            </Route>
            <Route path="/Employee">
              <Employee />{' '}
            </Route>
            <Route path="/EmployeeRedirect">
              <EmployeeRedirect />{' '}
            </Route>
            <Route path="/Termsofuse">
              <Termsofuse />{' '}
            </Route>
            <Route path="/FooterTOU">
              <FooterTOU />{' '}
            </Route>
            <Route path="/Error">
              <_error />{' '}
            </Route>
          </Router>

          <GlobalFooter />
        </DashboardContextProvider>
      </DocContextProvider>
    );
  }

  return (
    <DocContextProvider>
      <DashboardContextProvider>
        <PermissionRoleContextProvider>
          <ConfigConstantsContextProvider>
            <FeatureFlagsContextProvider>
              <CBDownloadsContextProvider>
                <IBDownloadsContextProvider>
                  <Router>
                    <Route exact path="/">
                      <>
                        <GlobalHead title="Login" />
                        <USBGrid addClasses="blue-frame top-menu">
                          <USBColumn largeSpan={2} />
                          <USBColumn largeSpan={12}>
                            <ul>
                              <li>
                                <div className="usb-logo">
                                  <img src={logo}></img>
                                </div>
                              </li>
                            </ul>
                          </USBColumn>
                          <USBColumn largeSpan={2} />
                        </USBGrid>
                        <Login />
                        <GlobalFooter />
                      </>
                    </Route>
                    <Route path="/Redirect">
                      <RedirectLandingPage />{' '}
                    </Route>

                    <Route path="/FindByRequirementId">
                      <Layout>
                        <FindByRequirementId />
                      </Layout>
                    </Route>
                    <Route path="/DocumentTemplate">
                      <Layout>
                        {' '}
                        <DocumentTemplate />{' '}
                      </Layout>
                    </Route>
                    <Route path="/Reports/PortalSubmission">
                      <Layout>
                        {' '}
                        <Reports />{' '}
                      </Layout>
                    </Route>
                    <Route path="/Reports/Lookup">
                      <Layout>
                        {' '}
                        <GlobalSearch />
                      </Layout>
                    </Route>
                    <Route path="/Reports/PSAFilter">
                      <Layout>
                        {' '}
                        <PSAReports />{' '}
                      </Layout>
                    </Route>

                    <Route path="/Reports/VendorCompanyFilter">
                      <Layout>
                        {' '}
                        <VendorCompanyFilter />{' '}
                      </Layout>
                    </Route>

                    <Route path="/Reports/VendorUserFilter">
                      <Layout>
                        {' '}
                        <VendorUserFilter />{' '}
                      </Layout>
                    </Route>

                    <Route path="/Reports/CustomerAccessFilter">
                      <Layout>
                        {' '}
                        <CompanyAccessReport />{' '}
                      </Layout>
                    </Route>

                    <Route path="/ManageUser">
                      <Layout>
                        <AppRouting />
                      </Layout>
                    </Route>

                    <Route exact path="/ClosingBinders/Portfolio">
                      <Layout>
                        <CBPortfolioParent />{' '}
                      </Layout>
                    </Route>

                    <Route exact path="/Inbox">
                      <Layout>
                        <Inbox />
                      </Layout>
                    </Route>

                    <Route exact path="/Requirements">
                      <Layout>
                        <Requirements />
                      </Layout>
                    </Route>

                    <Route exact path="/Inbox/:id">
                      <Layout>
                        <InboxDetails />
                      </Layout>
                    </Route>

                    {/* <Route exact path="/Inbox/Portfolio">
                        <InboxParent />{' '}
                      </Route> */}

                    <Route exact path="/ClosingBinders/Portfolio/temp">
                      <Layout>
                        <ClosingBinderPortfolio />{' '}
                      </Layout>
                    </Route>

                    <Route exact path="/ClosingBinders/Portfolio/Requirements">
                      <Layout>
                        <ClosingBinders />
                      </Layout>
                    </Route>
                    <Route exact path="/Inbox/Portfolio/Requirements">
                      <Layout>
                        <InboxRequirement />
                      </Layout>
                    </Route>
                    <Route exact path="/ClosingBinders/Doctype">
                      <Layout>
                        <CBDoctypeReport />
                      </Layout>
                    </Route>

                    <Route exact path="/FeatureFlags">
                      <Layout>
                        <FeatureFlags />
                      </Layout>
                    </Route>

                    <Route exact path="/ClosingBinders/FileNettype">
                      <Layout>
                        <CBFileNettypeReport />
                      </Layout>
                    </Route>

                    <Route path="/MyProfile">
                      <Layout>
                        <Redirect to={'/ManageUser/my-profile'} />
                      </Layout>
                    </Route>
                    <Route path="/Notifications">
                      <Layout>
                        <Notifications />{' '}
                      </Layout>
                    </Route>
                    <Route path="/ReportingDashboard">
                      <Layout>
                        <ReportingDashboard />{' '}
                      </Layout>
                    </Route>
                    <Route path="/RequirementDetails">
                      <Layout>
                        <RequirementDetails />{' '}
                      </Layout>
                    </Route>
                    <Route path="/document-viewer">
                      <Layout>
                        <DocumentViewerPage />{' '}
                      </Layout>
                    </Route>
                    <Route path="/RedirectManageUser">
                      <Layout>
                        <RedirectManageUser />{' '}
                      </Layout>
                    </Route>
                  </Router>
                </IBDownloadsContextProvider>
              </CBDownloadsContextProvider>
            </FeatureFlagsContextProvider>
          </ConfigConstantsContextProvider>
        </PermissionRoleContextProvider>
      </DashboardContextProvider>
    </DocContextProvider>
  );
};

// App.propTypes = {
//   Component: PropTypes.node.isRequired,
//   // eslint-disable-next-line react/forbid-prop-types
//   pageProps: PropTypes.object,
//   router: PropTypes.shape({
//     pathname: PropTypes.string
//   }),
// }

// App.defaultProps = {
//   pageProps: undefined,
//   router: undefined
// }

export default App;