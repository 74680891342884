import React, { useEffect, useState } from 'react';
// @ts-ignore
import { USBButton } from '@usb-shield/components-react';
import Select from '../../components/atoms/Select/Select';
import { getDefaultFilters, getRequirementsRequirements } from './utils';
import USBDatePicker from '@usb-shield/react-date-picker';
import USBSearchInput from '@usb-shield/react-search-input';
const defaultDatePickerErrorMessages = {
  default: 'Enter a valid date.',
  118: 'Enter a valid date.',
  121: "You've reached the character limit. Please try again.",
  130: 'Please enter your date again.',
  201: 'Enter a valid date.',
  202: 'Date should be in the past.',
  203: 'Date should be in the future.',
  204: 'You must be at least 18 years old to apply.',
  205: 'You must be less than 120 years old to apply.',
  224: 'Date is required.',
  228: "That date isn't available. Please try again.",
  }
  
const defaultCalendarSettings = { occurrence: 'past', areBankHolidaysDisabled: false, areWeekendsDisabled: false }
const defaultOptions = {
  parentCompanyName: [],
  dealName: [],
  dealId: [],
  category: [],
  status: [],
  dueDate: {
    dateInput: {
        initValue: '', inputName: 'Due Date'
    }, calendar: { ...defaultCalendarSettings, occurrence: 'past', areBankHolidaysDisabled: false, areWeekendsDisabled: false  },
  },
};

function RequirementsFilters({ reqDealId,categories,requirements,reqCategory, filters, setFilters ,setRequirements, setStatus,setCurrentPage,setReqDealId,setReqStatus,setReqCategory}) {
  const [options, setOptions] = useState(defaultOptions);
  const [advanceOptions, setAdvanceOptions] = useState(false);
  const [statusValue, setStatusValue] = useState([])
  // const statusOption = new Map();
  useEffect(() => {
    const newOptions = (requirements || []).reduce((acc, current) => {
      const {
        category,
        parentCompanyName, 
        dealName, 
        dealId ,
        status,
      } = current;

      return {
        ...acc,
        parentCompanyName: [
          ...acc.parentCompanyName,
          {
            label: parentCompanyName,
            value: parentCompanyName,
          },
        ],
        dealName: [
          ...acc.dealName,
          {
            label: dealName,
            value: dealName,
          },
        ],
        dealId: [
          ...acc.dealId,
          {
            label: dealId,
            value: dealId,
          },
        ],

        category: [
          ...acc.category,
          {
            label: category,
            value: category,
          },
        ],
        status: [
          {
            label: "Not Submitted",
            value: "Not Submitted",
          },
          {
            label: "Under Review",
            value: "Under Review",
          },
          {
            label: "Needs Action",
            value: "Needs Action",
          },
          {
            label: "Submitted",
            value: "Submitted",
          },
          {
            label: "Completed",
            value: "Completed",
          },
        ],
      };
    }, defaultOptions);
    setOptions(newOptions);

    const finalarray = newOptions.status.filter((obj, index)=>{
      return index === newOptions.status.findIndex(o => obj.label === o.label);
    })

    setStatusValue(finalarray);
    
  }, [requirements]);

  async function onDealChange(e) {
    setCurrentPage(1);
    setReqDealId(e.target.value)
    // sessionStorage.setItem('defaultFilters',JSON.stringify({...filters,"dealId":{"label":e.target.value,"value":e.target.value}}));
  }
  async function toggleAdvanceChange() {
  setAdvanceOptions(!advanceOptions)
  }
  async function onSelectFilterChange(e, metadata) {
    setCurrentPage(1);
    const { name } = metadata;
    const {value}= e;
    // console.log(name , ' ',value);
    if(name=="status"){
      setReqStatus(value)
       }
       if(name=="category"){
        setReqCategory(value)
       }
    await setFilters((previous) => {
      if (e) {
        return { ...previous, [name]: e };
      }
      const { [name]: selectName, ...rest } = previous;
      return rest;
    });
    
    // sessionStorage.setItem('defaultFilters',JSON.stringify({...filters,[name]:{"label":value,"value":value}}));
  }

  function onClear() {
    // sessionStorage.removeItem('defaultFilters')
    setFilters(getDefaultFilters());
    window.location.href = '/Requirements';
  }

  return (
    <section className="filters-container">
      <section className="filters-all">
        {/* parentCompanyName */}
        
        <section className="filter-item p-t-md">
           <USBSearchInput
                  id="cb-table-search"
                  name="test-name"
                  addClasses="cb-table-search"
                  type="search"
                  isError={false}
                  ariaProps={{
                    label: 'Deal Id',
                  }}
                  // initSearchInputValue={reqDealId}
                  placeholder="Deal Id"
                  handleChange={onDealChange}
                />

        </section>
        {/* <section className="filter-item p-t-md">
          <USBButton
            name="clearfilter"
            variant="primary"
            handleClick={toggleAdvanceChange}
            addClasses="clear-button"
          >
           {advanceOptions?'Hide':'Show'}  Advance filters
          </USBButton>
        </section>  */}
        
       
      
        
     

        {/* {advanceOptions && (<> */}
        <section className="filter-item p-t-md">
          <Select
            label="Status"
            name="status"
            value={filters.status}
            isClearable
            options={[
             {
                label: "Not Submitted",
                value: "Not Submitted",
              },
              {
                label: "Under Review",
                value: "Under Review",
              },
              {
                label: "Needs Action",
                value: "Needs Action",
              },
              {
                label: "Submitted",
                value: "Submitted",
              },
              {
                label: "Completed",
                value: "Completed",
              },
            ]}
            onChange={onSelectFilterChange}
          ></Select>
        </section>

        <section className="filter-item p-t-md">
          <Select
            label="Document Type"
            name="category"
            value={filters.category}
            isClearable
            options={categories}
            onChange={onSelectFilterChange}
          ></Select>
        </section>
      
        {/* <section className="filter-item p-t-md"></section> */}
        <section className="filter-item p-t-md">
          <USBButton
            name="clearfilter"
            variant="secondary"
            handleClick={onClear}
            addClasses="clear-button"
          >
            Clear filters
          </USBButton>
        </section>
        {/* <section className="filter-item p-t-md">
          <Select
            label="Document Type"
            name="category"
            isClearable
            options={options.category.filter((value, index, self) => {
              return self.findIndex(v => v.value === value.value) === index;
            })}
            value={filters['category']}
            onChange={onSelectFilterChange}
          ></Select>
        </section> */}

        {/* <section className="filter-item p-t-md">
          <Select
            label="Parent Company"
            name="parentCompanyName"
            isClearable
            options={options.parentCompanyName}
            value={filters['parentCompanyName']}
            onChange={onSelectFilterChange}
          ></Select>
        </section> */}
        
        {/* <section className="filter-item p-t-md">
          <Select
            label="Deal Name"
            name="dealName"
            isClearable
            options={options.dealName}
            value={filters['dealName']}
            onChange={onSelectFilterChange}
          ></Select>
        </section> */}
        {/* </>)} */}
        {/* <section className="filter-item p-t-md">
        <USBDatePicker
         id="asOfDate"
         inputName="asOfDate"
           addClasses="asOfDate"
           dynamicInputValue={defaultOptions.dueDate.dateInput.initValue}
           statusUpdateCallback={(e) => {
                          if ((e.event == "onChange" || e.event == "dynamicValue")) {
                           
                                const name = {name:'asOfDate'}
                                const dateValue = new Date(e.inputValue).toLocaleDateString('fr-CA');
                                const DatepickerValue ={label:dateValue,value:dateValue}
                                e.inputValue.length >0 ? onSelectFilterChange(DatepickerValue,name) : onSelectFilterChange('',name)
                               
                                  let filtersDate = {
                                        ...filters, dueDate: {
                                                                    dateInput: {
                                                                     initValue: e.inputValue, labelText: 'Due Date',
                                                                     }, calendar: { ...defaultCalendarSettings, occurrence: 'past', areBankHolidaysDisabled: false, areWeekendsDisabled: false  }
                                                                  }
                                                  }
                                                       
                                    }
           }}
          settings={{
            dateInput: {
              initValue: filters?.dueDate?.length==10 ? filters?.dueDate : undefined,
              isDateOfBirth: true,
              minimumAge: 0,
              maximumAge: 10,
              maxlength: 10,
              showRenderCount: false,
              inputName: 'date-picker_single-date--default',
              isDisabled: false,
              isOptional: false,
              isReadOnly: false,
              dataTestId: null,
            },
            calendar: {
              id: 'datepicker',
              ...defaultCalendarSettings,
              labelText: 'Due Date',
              areBankHolidaysDisabled:  false,
              areWeekendsDisabled: false ,
                occurrence:  'past' ,
                numberOfYearsToShow: '10',
              
            },
          }}
          content={{
            dateInput: {
              calendarButtonAriaLabel: 'Choose date',
              helperText: 'mm/dd/yyyy',
              helperTextOptional: 'Optional',
              labelText: 'Due Date',
              errorMessages: {
                ...defaultDatePickerErrorMessages,
                default: 'Please enter a valid date.',
                202: 'Date should be in the past.',
                203: 'Date should be in the future.',
                221: 'Enter no more than 10 characters.',
                213: 'Enter numbers only.',
                224: '(Optional field)',
                228: "That date isn't available. Please try again.",
              },
            },
            inputName: 'asOfDate',
          }}

                                            />
        </section> */}
       
      </section>
    </section>
  );
}

export default RequirementsFilters;
