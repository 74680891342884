import React, { useState, useEffect, useContext, Suspense } from 'react';
import Loader from '../../components/utilities/Loader';
import { ConfigConstantsContext } from '../../components/Hooks/useConfigConstants';
import { useHistory } from 'react-router-dom';


const RedirectManageUser = () => {
    const {configDetails, loading} = useContext(ConfigConstantsContext)
    const history = useHistory()

    useEffect(()=>{
        sessionStorage.setItem("config",configDetails)
        setTimeout(()=>{
            window.location.href = "/ManageUser"
       },2000)
    },[loading])

    return (
        <>
           {loading && <Loader></Loader>}
        </>
    )
}

export default RedirectManageUser

