import React, { Component, Suspense } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import { v4 as uuidv4 } from 'uuid';
import { endpoints } from '../../../config/connections';
import { parseJwt } from '../../components/utilities/Utils';
const EmployeeLoginModule = React.lazy(() =>
  import('@usb-sharedauth/react-login-npm-module/dist/esm/EmployeeLoginModule/EmployeeLoginModule.js')
);

const getCorrelationID = () => {
  const correlationId = uuidv4();
  typeof sessionStorage !== 'undefined'
    ? sessionStorage.setItem('correlationId', correlationId)
    : '';
  return correlationId;
};

const configHeader = {
  'Tenant-ID': 'USB',
  'App-ID': 'CDC',
  'Channel-ID': 'emp-web',
  'App-Version': '1',
  AK: `${endpoints.configAK}`,
  'Correlation-ID': getCorrelationID(),
};
const configSettings = {
  transmitConfigs: {
    URL: endpoints.transmit,
    APP_ID: 'cdc_web',
    policyIds: {
      EMPLOYEE_LOGIN: 'employee_signon',
    },
    policyParams: {
      routingKey: endpoints.authRoutingKey,
      appId: 'CDC',
      tenantId: 'USB',
      channelId: 'web',
      appVersion: '1',
    },
  },
  urls: {
    employeeLogin: {
      REDIRECT_URL: `${endpoints.redirect}/Employee`,
      AUTHORIZATION_CODE: `${endpoints.authCodeUrl}/as/authorization.oauth2`,
      ACCESS_TOKEN: `${endpoints.authCodeUrl}/as/token.oauth2`,
      ID_TOKEN: `${endpoints.authCodeUrl}/idp/userinfo.openid`,
    },
  },
};

class Employee extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
      window.process = {
        ...window.process,
        env: {
          LOG_LEVEL: 'info',
        },
      };
    }
  onTransmitAuthorizationFailure = () => {};

  onTransmitAuthorizationSuccess = (response) => {
    const CDCLandingPage = this.getRedirectURL(); 
    sessionStorage.removeItem("portfolioFilters")
    sessionStorage.removeItem('userData')
    sessionStorage.removeItem('remote_user')
    localStorage.setItem('tok', response.token);
    sessionStorage.setItem('tok', response.token);
    sessionStorage.setItem('loggedUser', parseJwt(response.token).sub);
    sessionStorage.setItem('assignedUser', parseJwt(response.token).sub);
    sessionStorage.setItem('type', parseJwt(response.token).pid);
    sessionStorage.setItem('role', parseJwt(response.token).custom_data.role);
    sessionStorage.setItem('at', parseJwt(response.token).custom_data.at);
    sessionStorage.setItem(
      'currentSession',
      sessionStorage.getItem('currentSession')
    );

    
    window.location.href = CDCLandingPage;
  };


  getRedirectURL = () => {
    const docIdFromEmail = localStorage.getItem('redurl');
    const docIdFromEmailCB = localStorage.getItem('cbredurl');
    const isManageUser = localStorage.getItem('mu');
    if (docIdFromEmail) {
      return `${endpoints.redirect}/RequirementDetails?id=${docIdFromEmail}`;
    } else  if (docIdFromEmailCB) {
      return `${endpoints.redirect}/ClosingBinders/Portfolio/Requirements?id=${docIdFromEmailCB}`;
    } else if (isManageUser) {
      return `${endpoints.redirect}/ManageUser`;
    } else if(localStorage.getItem("redirectURL") && localStorage.getItem("redirectURL")?.includes("route=manage")){
      localStorage.removeItem("redirectURL")
      return `${endpoints.redirect}/RedirectManageUser`;
    }else {
      return `${endpoints.redirect}/Redirect`;
    }
  };

  render() {
    return (
      <>
        <USBGrid addClasses="content-frame">
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            <div className="page-content-medium">
              <div className="page-content">
                <div id="test-spinner" />
                <Suspense fallback={<div>Loading...</div>}>
                  <EmployeeLoginModule
                    autoLoad
                    configSettings={configSettings}
                    configApiHeaders={configHeader}
                    configAPIURL={endpoints.configApiURL}
                    clientId={'CDC'}
                    redirectURI={`${endpoints.redirect}/Employee`}
                    onTransmitAuthorizationFailure={
                      this.onTransmitAuthorizationFailure
                    }
                    onTransmitAuthorizationSuccess={
                      this.onTransmitAuthorizationSuccess
                    }
                  />
                </Suspense>
              </div>
            </div>
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
      </>
    );
  }
}

export default Employee;
