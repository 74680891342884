import { EDMDocumentViewer } from '@usb-edm/react-document-viewer';
import { EDMSideModal } from '@usb-edm/react-side-modal';
import React, { useEffect, useRef, useState } from 'react';

import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBPagination,
  USBSelect,
  USBLink,
} from '@usb-shield/components-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Checkbox } from 'primereact/checkbox';
import callApi from '../../components/utilities/callApi';
import {
  saveAsFile,
  getQS,
  previewFile,
} from '../../components/utilities/Utils';
import { endpoints } from '../../../config/connections';
import { USBIconShow, USBIconClose } from '@usb-shield/react-icons';
import greenTick from '../../../assets/img/green-tick.svg';
import greyCheck from '../../../assets/img/grey_check.png';
import threeDots from '../../../assets/img/threedots.png';
import useUserRole from '../../components/Hooks/use-UserRole';

const getFileListsfromSessionStorage = () => {
  let fileLists = [];
  if (sessionStorage.getItem('fileNetIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileNetIdLists'));
  }
  return fileLists;
};

const removeFileListSessionStorage = (name) => {
  let fileLists = getFileListsfromSessionStorage();
  if (fileLists.length > 0) {
    console.log('removing');
    const index = fileLists.indexOf(name);
    if (index > -1) {
      fileLists.splice(index, 1);
    }
    sessionStorage.setItem('fileNetIdLists', JSON.stringify(fileLists));
  } else {
    console.log('deleting session');
    sessionStorage.removeItem('fileNetIdLists');
    fileLists = [];
  }
  console.log(fileLists);
};

const appendFileListSessionStorage = (name) => {
  console.log('appending', name);
  const fileLists = getFileListsfromSessionStorage();

  if (!(fileLists.indexOf(name) > -1)) {
    fileLists.push(name);
  }
  sessionStorage.setItem('fileNetIdLists', JSON.stringify(fileLists));
};

const getDownloadListsfromSessionStorage = () => {
  let fileLists = [];
  if (sessionStorage.getItem('fileDownloadIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileDownloadIdLists'));
  }
  return fileLists;
};

const removeDownloadListSessionStorage = (
  id,
  setDownloadEnabled,
  count,
  setCount
) => {
  if (id != null) {
    let fileLists = getDownloadListsfromSessionStorage();
    if (fileLists.length > 1) {
      const index = fileLists.indexOf(id);
      if (index > -1) {
        fileLists.splice(index, 1);
      }
      const temp = fileLists.length;
      setCount(temp);
      sessionStorage.setItem('fileDownloadIdLists', JSON.stringify(fileLists));
    } else {
      const temp = fileLists.length;
      setCount(temp - 1);
      setDownloadEnabled(false);
      sessionStorage.removeItem('fileDownloadIdLists');
      fileLists = [];
    }
  }

};

const appendDownloadListSessionStorage = (
  id,
  setDownloadEnabled,
  count,
  setCount
) => {
  if (id != null) {
    const fileLists = getDownloadListsfromSessionStorage();
    if (fileLists.indexOf(id) == -1) {
      fileLists.push(id);
      const temp = fileLists.length;
      setCount(temp);
    }
    setDownloadEnabled(true);
    sessionStorage.setItem('fileDownloadIdLists', JSON.stringify(fileLists));
  }
};

const isPresentInStorage = (id, setActionsDrop) => {
  const fileLists = getDownloadListsfromSessionStorage();
  let checked = false;
  if (fileLists.length >= 1 && id != null) {
    const index = fileLists.indexOf(id);
    if (index > -1) {
      checked = true;
    }
  }
  setActionsDrop(0);

  return checked;
};

export const selectDownloadFile = (
  items,
  setActionsDrop,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setLoading,
  selectAll,
  setSelectAll,
  setDownloadEnabled,
  count,
  setCount,
  index
) => {
  setLoading(true);
  const newData = reportingDetailsData;
  items.forEach((item, i) => {
    if (item.downloadId != null && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
      const tempIndex = index == -1 ? i : index;
      if (index == -1 && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
        if (!selectAll && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
          appendDownloadListSessionStorage(
            item.downloadId,
            setDownloadEnabled,
            count,
            setCount
          );
          newData[tempIndex].checked = true;
        } else {
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            setSelectAll(false)
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        }        // individual checkox is clicked
      } else {
        if (selectAll && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
          // select all option is checked
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            setSelectAll(false);
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        } else {
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            if (
              getDownloadListsfromSessionStorage().length ==
              reportingDetailsData.length - 1
            ) {
              setSelectAll(true);
            }
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        }
      }
    }
  });
  // console.log(newData);
  setActionsDrop(0);
  getDocumentListingNoAPI(setReportingDetailsData, newData);

  setTimeout(() => {
    setLoading(false);
  }, 500);
};

export const selectMarkedAsFinal = (
  items,
  reportingDetailsData,
  setActionsDrop,
  getDocumentListing,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  renamedFlag,
  setLoading,
  setIsDirty,
  selectMarkfinal,
  setSelectMarkfinal,
  indexValue,
) => {
  setLoading(true);
  const newData = reportingDetailsData;
  items.forEach((item, i) => {
    if (item.id !== null && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
      const tempIndex = indexValue == -1 ? i : indexValue;
      if (indexValue == -1 && item.id && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
        if (!selectMarkfinal && item.id && (item.edmStatus !== 'Success EDM' && item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
          appendFileListSessionStorage(item.id)
          newData[tempIndex].MarkedasFinalValue = true;
          setReportingDetailsData(newData)
        }
        else {
          if (selectMarkfinal && item.id && (item.edmStatus !== 'Success EDM' && item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed') || isPresentInStorage(item.id, setActionsDrop)) {
            setSelectMarkfinal(false);
            removeFileListSessionStorage(item.id);
            newData[tempIndex].MarkedasFinalValue = false;
            setReportingDetailsData(newData)
          }
        }
      }
    }
  });
  setActionsDrop(0);
  getDocumentListingNoAPI(setReportingDetailsData, newData);
  setTimeout(() => {
    setLoading(false);
  }, 500);
};

export const markFinal = (
  final,
  item,
  setActionsDrop,
  getDocumentListing,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setLoading,
  setIsDirty,
  selectMarkfinal,
  setSelectMarkfinal,
  showWarning
) => {
  setIsDirty(true);
  showWarning()
  if (item.MarkedasFinalValue === false) {
    Object.assign(item, { MarkedasFinalValue: true });
    appendFileListSessionStorage(item.id);
  } else if (item.MarkedasFinalValue === true || isPresentInStorage(item.id, setActionsDrop)) {
    Object.assign(item, { MarkedasFinalValue: false });
    removeFileListSessionStorage(item.id);
    setSelectMarkfinal(false)
  }
  setActionsDrop(0);
  setTimeout(() => {
    setLoading(false);
  }, 500);
};


export const markAsFinal = (
  id,
  reportingDetailsData,
  setActionsDrop,
  getDocumentListing,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  renamedFlag,
  setFailedFlag,
  setMessageContent,
  setSuccessFlag,
  setLoading,
  setIsDirty,
  selectMarkfinal,
  setSelectMarkfinal,
  showWarning
) => {
  setSuccessFlag(false);
  setFailedFlag(false);
  setLoading(true);
  reportingDetailsData.forEach((item) => {
    if (item.MarkedasFinalValue === false && item.id === id) {
      markFinal(
        true,
        item,
        setActionsDrop,
        getDocumentListing,
        getDocumentListingNoAPI,
        setReportingDetailsData,
        reportingDetailsData,
        setLoading,
        setIsDirty,
        selectMarkfinal,
        setSelectMarkfinal,
        showWarning
      );
    } else if (item.MarkedasFinalValue === true && item.id === id) {
      markFinal(
        false,
        item,
        setActionsDrop,
        getDocumentListing,
        getDocumentListingNoAPI,
        setReportingDetailsData,
        reportingDetailsData,
        setLoading,
        setIsDirty,
        selectMarkfinal,
        setSelectMarkfinal,
        showWarning
      );
    }
  });
};

export const toggleAccordion = (id, openedAccordion, setOpenedAccordion) => {
  if (openedAccordion === id) {
    setOpenedAccordion(0);
  } else {
    setOpenedAccordion(id);
  }
};

export const showActionsDropdown = (id, actionsDrop, setActionsDrop) => {
  if (actionsDrop !== id) {
    setActionsDrop(id);
  } else {
    setActionsDrop('0');
  }
};

export const handleKeyDown = (event, id, actionsDrop, setActionsDrop) => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    showActionsDropdown(id, actionsDrop, setActionsDrop);
  }
  if (event.keyCode === 27) {
    setActionsDrop('0');
  }
};

export const threeDotKeyDown = (event, setActionsDrop) => {
  if (event.keyCode === 27) {
    setActionsDrop('0');
  }
};

export const versionKeyDown = (
  event,
  id,
  openedAccordion,
  setOpenedAccordion
) => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    toggleAccordion(id, openedAccordion, setOpenedAccordion);
  }
  if (event.keyCode === 27) {
    setOpenedAccordion(0);
  }
};

export const downloadDocument = (
  id,
  setLoading,
  setActionsDrop,
  setFileDownload,
  getDocumentListing,
  setReportingDetailsData,
  setMessageContent,
  setFailedFlag
) => {
  setLoading(true);
  setFileDownload(true);

  const apiURL = `${endpoints.api}/document-management/file-download/v1/files?fileIdList=${id}`;
  callApi
    .getApiData(apiURL)
    .then((result) => {
      console.log('result', result);
      getDocumentListing(setReportingDetailsData);
      saveAsFile(
        JSON.parse(result.data[0].binaryContent).binary,
        JSON.parse(result.data[0].binaryContent).fileName
      );

      setLoading(false);
      setActionsDrop('0');
    })
    .catch((error) => {
      if (error?.response?.data?.statusCode !== 200) {
        setMessageContent(error?.response?.data?.errorMessage);

        setFailedFlag(true);
      }
      setLoading(false);
      setActionsDrop('0');
    });
};

export const confirmReplace = (
  setErrorModal,
  setLegalHold,
  setErrorModalTxt,
  setErrorModalTitle
) => {
  setErrorModal(true);
  setLegalHold(true);
  const contentTxt = (
    <div>
      For retention purposes, this file can&apos;t be replaced right now.
    </div>
  );
  setErrorModalTxt(contentTxt);
  setErrorModalTitle('Unable to replace file.');
};

export const replaceFile = (
  event,
  item,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setActionsDrop,
  getDocumentListing,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  setSelectMarkfinal,
  setIsDirty,
  showWarning,
) => {
  setSuccessFlag(false);
  setFailedFlag(false);
  const acceptedFileExtentions = [
    'pdf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'xml',
    'jpeg',
    'jpg',
  ];
  const formData = new FormData();
  formData.append('reqId', getQS('id'));
  formData.append('file', event.target.files[0]);
  // formData.append('areaToStore ', 'Temporary')
  formData.append('user ', sessionStorage.getItem('loggedUser'));
  const ext = event.target.value.split('.').pop();
  if (event.target.files[0].size > 104857600) {
    setFailedFlag(true);
    setMessageContent(
      `We were unable to upload your document. Make sure your file size is less than 100MB and try again.`
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
    return false;
  }
  if (acceptedFileExtentions.indexOf(ext.toLowerCase()) < 0) {
    setFailedFlag(true);
    setMessageContent(
      `We were unable to upload the document. Make sure your file is in a supported format (${acceptedFileExtentions.join(
        ','
      )}) and try again.`
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
    return false;
  }
  const userId = sessionStorage.getItem('loggedUser');
  const docId = getQS('id');
  const filenetId = item.downloadId ? item.downloadId : item.id;
  setLoading(true);
  const url = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/${docId}/files/fileReplace?fileId=${filenetId}`;
  return callApi
    .postApiUpload(url, formData)
    .then((response) => {
      if (response.status === 200) {
        getDocumentListing(setReportingDetailsData);
        setSuccessFlag(true);
        setMessageContent(`You’ve successfully uploaded your document.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setIsDirty(true);
        showWarning()
        setTimeout(() => {
          setSuccessFlag(false);
          setSelectMarkfinal(false);
        }, 5000);
      } else {
        // console.log('upload failed')
      }
      setLoading(false);
      //sessionStorage.removeItem('fileNetIdLists');
      setActionsDrop('0');
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
      setActionsDrop('0');
    });
};

export const handleKeyDownReplace = (e, replaceInputFile) => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    e.preventDefault();
    replaceInputFile.click();
  }
  return false;
};

export const editFileName = (
  id,
  setLoading,
  getDocumentListing,
  getDocumentListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setCloseRenameModal,
  setRenamedTextReceived,
  setCurrentFilenetId,
  renameFocus
) => {
  setLoading(true);
  const url = `${endpoints.api}/document-management/file-upload/v1/files/getFileRename?fileId=${id}`;
  callApi
    .getApiDataRequirement(url)
    .then((response) => {
      if (response.status === 200) {
        setCurrentFilenetId(id);
        setCloseRenameModal(true);
        setRenamedTextReceived(response.data);
        setLoading(false);
      }
    })
    .catch(() => {
      setLoading(false);
      // console.log(error)
    });
  renameFocus.current.focus();
};

export const confirmDelete = (
  data,
  setDeleteId,
  setErrorModal,
  setErrorModalTxt,
  setErrorModalTitle,
  setLegalHold,
  setCount,
  count,
  setDownloadEnabled
) => {
  setDeleteId(data.id);
  setErrorModal(true);
  removeDownloadListSessionStorage(data.id,setDownloadEnabled,count,setCount)
  if (data.legalHoldFlag === 'true') {
    setLegalHold(true);
    const contentTxt = (
      <div>
        For retention purposes, this file can&apos;t be deleted right now.
      </div>
    );
    setErrorModalTxt(contentTxt);
    setErrorModalTitle('Unable to delete file.');
  } else {
    setLegalHold(false);
    setErrorModalTxt('You can’t undo this action');
    setErrorModalTitle('Are you sure you want to delete this document?');
  }
};

const DocumentList = ({
  setLoading,
  reportingDetailsData,
  openedAccordion,
  setOpenedAccordion,
  employee,
  actionsDrop,
  setActionsDrop,
  getDocumentListing,
  getDocumentListingNoAPI,
  requirementResults,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setDownloadEnabled,
  count,
  setCount,
  setDeleteId,
  setErrorModal,
  setErrorModalTxt,
  setErrorModalTitle,
  setReportingDetailsData,
  setCloseRenameModal,
  setRenamedTextReceived,
  setCurrentFilenetId,
  setLegalHold,
  setFileDownload,
  selectAll,
  setSelectAll,
  selectMarkfinal,
  setSelectMarkfinal,
  setIsDirty,
  removeWarning,
  showWarning,
  setDisableSubmit,
  isMarkAsFinalAllowed
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [binaryData, setBinaryData] = useState(null);
  const [fileName, setFilename] = useState(null);

  const preview = (id, setLoading, setActionsDrop) => {

    window.open(`/document-viewer?url=` + id)

  };

  let replaceInputFile = '';
  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (
        event.srcElement.className !== 'threedots-anchor' &&
        event.srcElement.className !== 'threedots-upload'
      ) {
        setActionsDrop('0');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  useEffect(()=>{
    setDisableSubmit(reportingDetailsData.filter(item=>
      item.edmStatus == 'Success Temp' ||
      item.edmStatus == 'Success'
    ).length<=0)
  },[reportingDetailsData])


  const renameFocus = useRef(null);
  const toolbar = {
    // print: true,
    // open: true,
    // download: true,
    // delete: false,
    // rotate: true
    // zoomInOut: true,
    // zoomSelect: true,
  };
  const location = useLocation();
  const tempUserRole = useUserRole();
  // const Navigate = useNavigate();
  return (
    <div>
      {reportingDetailsData && reportingDetailsData.length === 0 ? (
        ''
      ) : (
        <div className={tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? "container-header" : "container-header-external"}>
          {/* <div className="filedownload title">Select All</div> */}
          <div className="selectAllHeaderG grid-title" data-testid="chck-box">
            <Checkbox
              onChange={(event) => {
                if (event.checked) {
                  setSelectAll(true);
                } else {
                  setSelectAll(false);
                }
                selectDownloadFile(
                  reportingDetailsData,
                  setActionsDrop,
                  getDocumentListingNoAPI,
                  setReportingDetailsData,
                  reportingDetailsData,
                  setLoading,
                  selectAll,
                  setSelectAll,
                  setDownloadEnabled,
                  count,
                  setCount,
                  -1 // indicates select all
                );
              }}
              disabled={
                ((reportingDetailsData.downloadId) || (reportingDetailsData.edmStatus !== 'SentToEDM' && reportingDetailsData.edmStatus !== 'SentToEdmTemp' && reportingDetailsData.edmStatus !== 'Failed Temp' && reportingDetailsData.edmStatus !== 'Failed')) ? false : true
              }
              checked={selectAll}
              ariaLabelledBy="mark-final"
            />
            &nbsp;Select All
          </div>
          <div className={tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? "fileNameHeaderG grid-title" : "fileNameHeaderG-external grid-title"}>File name</div>
          {tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? (<div className="documentTitleHeaderG grid-title">Document Title</div>) : ''}
          <div className="uploadedOnHeaderG grid-title">Uploaded On </div>
          <div className="uploadedByHeaderG grid-title">Uploaded By</div>
          <div className="finalVersionHeaderG">
            {tempUserRole === 'employee' && !sessionStorage.getItem('remote_user')  ? (<div>
              { isMarkAsFinalAllowed && <Checkbox
                onChange={(event) => {
                  if (event.checked) {
                    setSelectMarkfinal(true);
                    setIsDirty(true);
                    showWarning();
                  } else {
                    setSelectMarkfinal(false);
                    setIsDirty(false);
                    removeWarning();
                  }

                  selectMarkedAsFinal(
                    reportingDetailsData,
                    reportingDetailsData,
                    setActionsDrop,
                    getDocumentListing,
                    getDocumentListingNoAPI,
                    setReportingDetailsData,
                    reportingDetailsData.renamedFlag,
                    setLoading,
                    setIsDirty,
                    selectMarkfinal,
                    setSelectMarkfinal,
                    -1  //indicates select all
                  )
                }}
                checked={selectMarkfinal}
                disabled={((reportingDetailsData.downloadId) ||
                  (reportingDetailsData.permanentLocationFlag ||
                    reportingDetailsData.edmStatus == 'SentToEDM' ||
                    reportingDetailsData.edmStatus == 'SentToEdmTemp' ||
                    reportingDetailsData.edmStatus == 'Failed Temp' ||
                    reportingDetailsData.edmStatus == 'Failed')) ? true : false
                }
                ariaLabelledBy="mark-final"
              />}<span className='grid-title'>{ isMarkAsFinalAllowed ? "Final Version?" : "Preview"}</span></div>) : ''}
          </div>
          <div className="actionsHeaderG grid-title" >Actions</div>
        </div>
      )}

      {reportingDetailsData && reportingDetailsData.length > 0
        ? reportingDetailsData.map((item, index) => (
          <div
            className={'listContainer'}
            key={item.id}
            data-testid="file-container"
          >
            <div className="closebutton">
              <EDMSideModal
                modalTitle={'Preview'}
                handleModalClose={() => setIsOpenModal(false)}
                isOpen={isOpenModal && fileData}
              >
                <EDMDocumentViewer
                  file={fileData}
                  // handleDocChange={handleStatusUpdate}
                  toolbar={toolbar}
                  documentView="single"
                // setDocLoadError={setDocLoadError}
                // docLoadError={docLoadError}
                ></EDMDocumentViewer>
              </EDMSideModal>

            </div>
            <div className={tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? "container-body" : "container-body-external"}>
              <div className="selectAllBodyG grid-body">
                <Checkbox
                  className='selectAllCheckBoxG'
                  onChange={() => {
                    selectDownloadFile(
                      [item],
                      setActionsDrop,
                      getDocumentListingNoAPI,
                      setReportingDetailsData,
                      reportingDetailsData,
                      setLoading,
                      selectAll,
                      setSelectAll,
                      setDownloadEnabled,
                      count,
                      setCount,
                      index
                    );
                  }}
                  disabled={
                    ((reportingDetailsData[index].downloadId) && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) ? false : true
                  }
                  checked={item.checked || getDownloadListsfromSessionStorage().includes(item.downloadId)}
                  ariaLabelledBy="mark-final"
                />
              </div>
              <div className={tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? "fileNameBodyG grid-body" : "fileNameBodyG-external grid-body"} data-testid="doc-list">
                <strong>{tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? item.title : (item.title || item.name)}</strong>
                <br />
                <span>{item.size}</span>
              </div>
              {tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? (
                <div className="documentTitleBodyG grid-body" data-testid="doc-list">
                  <strong className="tooltip-on-hover version-name">
                    {item.fullName.length > 0 ? item.fullName : item.name}
                  </strong><br />
                  {item.fullName !== '' ? (
                    <div className="tooltip-name">{item.fullName}</div>
                  ) : (
                    ''
                  )}

                </div>
              ) : ''}
              {/* <div className="filehead filehead2">
                {item.versions.length > 0 ? (
                  <div
                    tabIndex="0"
                    className="fileopener"
                    onClick={() =>
                      toggleAccordion(
                        item.id,
                        openedAccordion,
                        setOpenedAccordion
                      )
                    }
                    onKeyDown={(event) =>
                      versionKeyDown(
                        event,
                        item.id,
                        openedAccordion,
                        setOpenedAccordion
                      )
                    }
                  >
                    {openedAccordion === item.id ? (
                      <span
                        aria-label="Hide uploaded files version history"
                        className="arrow-icon right-arrow"
                      />
                    ) : (
                      <span
                        aria-label="Show uploaded files version history"
                        className="arrow-icon down-arrow"
                      />
                    )}
                  </div>
                ) : (
                  <div className="fileopener hideinxs" />
                )}
              </div> */}
              <div className="uploadedOnBodyg grid-body">
                <strong>{item.uploadedDate}</strong>
                <br />
                <span>{item.uploadedTime}</span>
              </div>
              <div className="uploadedByBodyG grid-body">
                {item.uploadedByName}
                {item.uploadedByMail ? (
                  <span className="email-to-name">
                    <br />
                    <a
                      href={`mailto:${item.uploadedByMail}`}

                    >
                      {item.uploadedByMail}
                    </a>
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className="finalVersionBodyG grid-body">
                <div className='status-preview'>
                  {isMarkAsFinalAllowed ? tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? (
                    <div>
                      {tempUserRole === 'employee' && !sessionStorage.getItem('remote_user') ? (<Checkbox
                        className='markAsFinalCheckBoxG'
                        onChange={() =>
                          markAsFinal(
                            item.id,
                            reportingDetailsData,
                            setActionsDrop,
                            getDocumentListing,
                            getDocumentListingNoAPI,
                            setReportingDetailsData,
                            item.renamedFlag,
                            setFailedFlag,
                            setMessageContent,
                            setSuccessFlag,
                            setLoading,
                            setIsDirty,
                            selectMarkfinal,
                            setSelectMarkfinal,
                            showWarning
                          )
                        }
                        checked={item.MarkedasFinalValue || getFileListsfromSessionStorage().includes(item.id)}
                        disabled={
                          item.permanentLocationFlag ||
                          item.edmStatus == 'SentToEDM' ||
                          item.edmStatus == 'SentToEdmTemp' ||
                          item.edmStatus == 'Failed Temp' ||
                          item.edmStatus == 'Failed' ||
                          item.markedAsFinal == true
                        }
                        ariaLabelledBy="mark-final"
                      />) : ''}
                    </div>
                  ) : (
                    <div >
                      {item.MarkedasFinalValue ? (
                        <div className="statusIconG" aria-label="Document marked as final">
                          <img src={greenTick} />
                        </div>
                      ) : (
                        <>
                          <div className="statusIconG" aria-label="Document pending review">
                            <img src={greyCheck} />
                          </div>
                        </>
                      )}
                    </div>
                  ) : ""}
                  <div className="previewIconG">
                    {
                      <Router>
                        <Link
                          to={location.pathname + location.search}
                          onClick={() => {
                            const nameExt = item.fullName
                              ? item.fullName.split('.').pop()
                              : item.name.split('.').pop();
                            nameExt != 'pdf'
                              // && (item.edmStatus !== 'Success EDM' && item.edmStatus !== 'Success FileNet' && item.edmStatus !== 'Success Temp')
                              ? alert('Only pdf document can be previewed')
                              : (item.edmStatus.includes('Success') === false) ?
                                alert("This document status is failed so could not be previewed")
                                : preview(
                                  item.downloadId ? item.downloadId : item.id,
                                  setLoading,
                                  setActionsDrop
                                );
                          }}
                        >
                          <USBIconShow
                            colorVariant={
                              (item.fullName
                                ? item.fullName.split('.').pop()
                                : item.name.split('.').pop()) != 'pdf'
                                // && (item.edmStatus !== 'Success EDM' && item.edmStatus !== 'Success FileNet' && item.edmStatus !== 'Success Temp')
                                ? 'grey60'
                                : 'interaction'
                            }
                            title="Preview"
                            desc="Preview File"
                            ariaHidden={true}
                            id="add-icon"
                            focusable={true}
                            tabindex={-1}
                          />
                        </Link>
                      </Router>
                    }
                  </div>
                </div>
                <br></br>
                <div className='statusTextG'>
                  {item.edmStatus == 'Failed Temp'
                    ? 'Failed'
                    : item.edmStatus == 'SentToEdmTemp'
                      ? 'Uploading'
                      : item.edmStatus == 'Success Temp' ||
                        item.edmStatus == 'Success'
                        ? 'Uploaded'
                        : item.edmStatus == 'Failed'
                          ? 'Failed'
                          : item.edmStatus == 'SentToEDM'
                            ? 'Finalizing'
                            : item.edmStatus == 'Success EDM' ||
                              item.edmStatus == 'Success FileNet' ||
                              item.edmStatus == 'Success'
                              ? 'Finalized'
                              : ''}
                </div>
              </div>
              <div className="actionBodyG grid-body" style={{ width: 10, paddingTop: 10 }}>
                <div className="action-dropdown">
                  <div
                    // style={{backgroundImage : `url('../../../assets/img/threedots.png')`}}
                    ref={renameFocus}
                    tabIndex="0"
                    role="listbox"
                    // className="threedots-drop"
                    data-testid="three-dots"
                    aria-label="Click for more options"
                    onClick={() =>
                      showActionsDropdown(
                        item.id,
                        actionsDrop,
                        setActionsDrop
                      )
                    }
                    onKeyDown={(event) =>
                      handleKeyDown(
                        event,
                        item.id,
                        actionsDrop,
                        setActionsDrop
                      )
                    }
                  >
                    <span className="threedots" aria-label="Actions">
                      <img src={threeDots} />
                    </span>
                  </div>
                  {actionsDrop === item.id ? (
                    <ul
                      data-testid="dot-list"
                      onKeyDown={(event) =>
                        threeDotKeyDown(event, setActionsDrop)
                      }
                    >
                      <li>
                        <button
                          type="button"
                          data-testid="download-file"
                          id="download"
                          className={
                            item.edmStatus == 'SentToEDM' ||
                              item.edmStatus == 'SentToEdmTemp' ||
                              item.edmStatus == 'Failed Temp' ||
                              item.edmStatus == 'Failed'
                              ? 'threedots-anchor disable-icon'
                              : 'threedots-anchor'
                          }
                          aria-label="download file"
                          onClick={() =>
                            downloadDocument(
                              item.downloadId,
                              setLoading,
                              setActionsDrop,
                              setFileDownload,
                              getDocumentListing,
                              setReportingDetailsData,
                              setMessageContent,
                              setFailedFlag
                            )
                          }
                        >
                          <span className="icon download" />
                          Download
                        </button>
                      </li>
                      {requirementResults.status === 'Completed' ? (
                        ''
                      ) : (
                        <li>
                          <button
                            type="button"
                            id="replace"
                            className="threedots-anchor"
                            aria-label="replace file"
                            onClick={() =>
                              item.legalHoldFlag === 'true'
                                ? confirmReplace(
                                  setErrorModal,
                                  setLegalHold,
                                  setErrorModalTxt,
                                  setErrorModalTitle
                                )
                                : ''
                            }
                            onKeyDown={(e) =>
                              item.legalHoldFlag === 'true'
                                ? confirmReplace(
                                  setErrorModal,
                                  setLegalHold,
                                  setErrorModalTxt,
                                  setErrorModalTitle
                                )
                                : handleKeyDownReplace(e, replaceInputFile)
                            }
                          >
                            {item.legalHoldFlag === 'true' ? (
                              ''
                            ) : (
                              <input
                                data-testid="replace-file"
                                type="file"
                                tabIndex="-1"
                                aria-label="replace input"
                                ref={(input) => {
                                  // assigns a reference so we can trigger it later
                                  replaceInputFile = input;
                                }}
                                onChange={(e) =>
                                  replaceFile(
                                    e,
                                    item,
                                    setSuccessFlag,
                                    setFailedFlag,
                                    setMessageContent,
                                    setLoading,
                                    setActionsDrop,
                                    getDocumentListing,
                                    getDocumentListingNoAPI,
                                    setReportingDetailsData,
                                    setSelectMarkfinal,
                                    setIsDirty,
                                    showWarning,
                                  )
                                }
                                onKeyDown={(e) =>
                                  handleKeyDownReplace(e, replaceInputFile)
                                }
                                className="threedots-upload"
                              />
                            )}
                            <span className="icon upload" />
                            Replace
                          </button>
                        </li>
                      )}
                      {employee && !sessionStorage.getItem('remote_user') ? (
                        <li>
                          <button
                            type="button"
                            id="rename"
                            className={
                              item.permanentLocationFlag ||
                                item.edmStatus == 'SentToEDM' ||
                                item.edmStatus == 'SentToEdmTemp' ||
                                item.edmStatus == 'Failed Temp' ||
                                item.edmStatus == 'Failed'
                                ? 'threedots-anchor disable-icon'
                                : 'threedots-anchor'
                            }
                            onClick={() =>
                              editFileName(
                                item.id,
                                setLoading,
                                getDocumentListing,
                                getDocumentListingNoAPI,
                                setReportingDetailsData,
                                reportingDetailsData,
                                setCloseRenameModal,
                                setRenamedTextReceived,
                                setCurrentFilenetId,
                                renameFocus
                              )
                            }
                          >
                            <span className="icon rename" />
                            Edit
                          </button>
                        </li>
                      ) : (
                        ''
                      )}
                      {requirementResults.status === 'Completed' ? (
                        ''
                      ) : (
                        <li>
                          <button
                            type="button"
                            id="delete"
                            data-testid="delete-file"
                            className={
                              item.permanentLocationFlag
                                ? 'threedots-anchor disable-icon'
                                : 'threedots-anchor'
                            }
                            onClick={() =>
                              confirmDelete(
                                item,
                                setDeleteId,
                                setErrorModal,
                                setErrorModalTxt,
                                setErrorModalTitle,
                                setLegalHold,
                                setCount,
                                count,
                                setDownloadEnabled
                              )
                            }
                          >
                            <span className="icon delete" />
                            Delete
                          </button>
                        </li>
                      )}
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            {openedAccordion === item.id ? (
              <div className="listFrame">
                {item.versions.length > 0
                  ? item.versions.map((subitem) => (
                    <div className="listFrame childContainer">
                      <div className="fileheadsubmain">
                        <div className="filethumb">
                          <span className="document-img sub-img" />
                        </div>
                        <div className="fileheadsubdata">
                          <div className="filedtxt" data-testid="doc-list">
                            <div>
                              <strong className="tooltip-on-hover version-name">
                                {subitem.name ? subitem.name : ''}
                              </strong>
                              {subitem.fullName !== '' ? (
                                <div className="tooltip-name">
                                  {subitem.fullName ? subitem.fullName : ''}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            {subitem.versions.length == 0 ||
                              subitem.versions === null ||
                              !subitem.versions ? (
                              ''
                            ) : (
                              <div>
                                <span className="version-number">
                                  ({JSON.stringify(subitem.versions)})
                                </span>
                              </div>
                            )}
                            <br />
                            <span>{subitem.size ? subitem.size : ''}</span>
                          </div>
                        </div>
                      </div>
                      <div className="fileDate">
                        {subitem.uploadedDate ? subitem.uploadedDate : ''}
                        <br />
                        <span>
                          {subitem.uploadedTime ? subitem.uploadedTime : ''}
                        </span>
                      </div>
                      <div className="uploadedBy">
                        {subitem.uploadedByName
                          ? subitem.uploadedByName
                          : ''}
                        <br />
                        <span className="email-to">
                          {subitem.uploadedByMail
                            ? subitem.uploadedByMail
                            : ''}
                        </span>
                      </div>
                      <div />
                      <div className="action-sub-span">
                        <div className="action-dropdown">
                          <span
                            tabIndex="0"
                            data-testid="download-file"
                            id="download"
                            className="threedots-anchor version-download"
                            onClick={() =>
                              downloadDocument(
                                subitem.downloadId,
                                setLoading,
                                setActionsDrop,
                                setFileDownload,
                                getDocumentListing,
                                setReportingDetailsData,
                                setMessageContent,
                                setFailedFlag
                              )
                            }
                          >
                            <span className="icon download" />
                            Download
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                  : ''}
              </div>
            ) : (
              ''
            )}
          </div>
        ))
        : ''}
    </div>
  );
};

DocumentList.propTypes = {
  setLoading: PropTypes.func.isRequired,
  reportingDetailsData: PropTypes.any.isRequired,
  openedAccordion: PropTypes.number.isRequired,
  setOpenedAccordion: PropTypes.func.isRequired,
  employee: PropTypes.bool.isRequired,
  actionsDrop: PropTypes.string.isRequired,
  setActionsDrop: PropTypes.func.isRequired,
  getDocumentListing: PropTypes.func.isRequired,
  getDocumentListingNoAPI: PropTypes.func.isRequired,
  requirementResults: PropTypes.any.isRequired,
  setSuccessFlag: PropTypes.func.isRequired,
  setFailedFlag: PropTypes.func.isRequired,
  setMessageContent: PropTypes.func.isRequired,
  setDownloadEnabled: PropTypes.func.isRequired,
  count: PropTypes.func.isRequired,
  setCount: PropTypes.func.isRequired,
  setDeleteId: PropTypes.func.isRequired,
  setErrorModal: PropTypes.func.isRequired,
  setErrorModalTxt: PropTypes.func.isRequired,
  setErrorModalTitle: PropTypes.func.isRequired,
  setReportingDetailsData: PropTypes.func.isRequired,
  setCloseRenameModal: PropTypes.func.isRequired,
  setRenamedTextReceived: PropTypes.func.isRequired,
  setCurrentFilenetId: PropTypes.func.isRequired,
  setLegalHold: PropTypes.func.isRequired,
};

export default DocumentList;
